/** This file suppose to contain custom elements styles. */
// Custom clickable icon styles block.
.clickable-icon-wrap {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .clickable-icon {
    margin: 8px;
    max-width: 24px;
    max-height: 24px;
    width: 100%;
    height: auto;
  }
}

.clickable-icon-wrap_disabled {
  cursor: not-allowed;
  opacity: 0.5;

  &:hover {
    background-color: inherit;
  }
}

// Custom clickable icon styles block.
.clickable-icon-wrap_small {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .clickable-icon {
    max-width: 14px;
    max-height: 14px;
    width: 100%;
    height: auto;
  }
}

.main-button {
  &.main-button_clear {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background: inherit;
  }
}

