// Landing pages for Imaging Centers
.centers-landing {
  .container {
    max-width: 1350px;
  }

  .centers-landing__wrapper--gray {
    background: #f8f8f8;
  }

  .centers-landing__text {
    margin: 35px 0 0;
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
    color: var(--color-gray4);

    @media (max-width: 575.98px) {
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .centers-landing__header {
    margin: 0;
    text-align: start;
    font-size: 36px;
    line-height: 44px;
    font-weight: 600;
    color: var(--color-gray4);

    @media (max-width: 991.98px) {
      margin-top: 30px;
      text-align: center;
    }

    @media (max-width: 575.98px) {
      margin-top: 10px;
      font-size: 26px;
      line-height: 34px;
      text-align: start;
    }
  }

  .centers-landing__subheader {
    margin: 0;
    font-size: 18px;
    line-height: 44px;
    font-weight: 600;
    color: var(--color-blue);
  }

  .centers-landing__half-content {
    max-width: 550px;
    margin-right: 20px;

    @media (max-width: 991.98px) {
      margin-bottom: 40px;
      margin-right: 0;
      text-align: center;
    }
  }

  .centers-landing__half-image {
    max-width: 550px;

    img {
      width: 100%;
    }

    @media (max-width: 991.98px) {
      margin-bottom: 30px;
      width: 100%;
    }
  }

  .get-most {
    margin-top: 80px;

    .centers-landing__half-content {
      max-width: 597px;
    }

    .centers-landing__header {
      font-size: 50px;
      line-height: 60px;
    }

    .centers-landing__text {
      margin-top: 20px;
      margin-bottom: 50px;
    }

    .centers-landing__half-image {
      max-width: 560px;
    }

    @media (max-width: 991.98px) {
      margin-top: 80px;
    }

    @media (max-width: 575.98px) {
      margin-top: 0;
      padding: 0 20px;

      .centers-landing__half-content {
        margin-bottom: 0;
      }

      .centers-landing__header {
        margin-top: -20px;
        font-size: 36px;
        line-height: 46px;
        text-align: start;
      }

      .centers-landing__text {
        text-align: start;
        margin-bottom: 0;
      }

      .centers-landing__half-image {
        max-width: 256px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .view-brain {
    margin-top: 42px;
    margin-bottom: 72px;

    .centers-landing__half-image {
      margin-right: 20px;
      max-width: 520px;
      text-align: right;

      img {
        max-width: 520px;
      }
    }

    .centers-landing__half-content {
      max-width: 520px;
      margin-right: 0;
      margin-top: 0;
    }

    @media (max-width: 991.98px) {
      margin-bottom: 70px;

      .centers-landing__half-image {
        text-align: center;
        margin-right: 0;
      }
    }

    @media (max-width: 575.98px) {
      margin-bottom: 60px;
      margin-top: 60px;
      padding: 0;

      .centers-landing__half-content {
        text-align: start;
        padding: 0 20px;
        margin-bottom: 0;
      }
    }
  }

  .share {
    padding-bottom: 30px;

    .centers-landing__half-content {
      max-width: 520px;
    }

    .centers-landing__half-image {
      max-width: 620px;
    }

    @media (max-width: 575.98px) {
      padding-bottom: 10px;

      .centers-landing__half-content {
        text-align: start;
        padding: 0 20px;
      }
    }
  }

  .organize {
    margin-top: 104px;
    margin-bottom: 90px;

    .centers-landing__half-image {
      text-align: right;
      margin-right: 20px;

      img {
        max-width: 530px;
      }
    }

    .centers-landing__half-content {
      max-width: 520px;
      margin-right: 0;
    }

    @media (max-width: 991.98px) {
      margin-top: 80px;

      .centers-landing__half-image {
        margin-right: 0;
        text-align: center;
      }
    }

    @media (max-width: 575.98px) {
      margin-top: 45px;
      margin-bottom: 60px;
      padding: 0 25px;

      .centers-landing__half-content {
        text-align: start;
      }
    }

  }

  .ready-to-see {
    margin-bottom: 80px;
    text-align: center;

    p {
      margin-bottom: 60px;
      font-size: 24px;
      line-height: 30px;
      font-weight: 500;
      color: var(--color-gray4);
    }

    strong {
      font-weight: 600;
    }

    @media (max-width: 575.98px) {
      padding: 0 20px;

      p {
        margin-bottom: 45px;
        font-size: 18px;
        line-height: 32px;
      }

      .button {
        font-size: 18px;
      }
    }
  }
}
