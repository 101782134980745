$background: #29548c;
$background-dark: #0d2f5b;
$border: #e1e1e1;
$border-dark: #c4c4c4;
$foreground: #f8f9fb;
$accent-grey: #c1e1ef;
$accent-blue: #8ac9e4;
$accent-blue-title: #c5e1ef;
$accent: #d4af37;
$black: #222222;
$white: #fff;
$dark: #11243c;
$warn: #d43737;

.p-table {
  padding: 32px 20px 40px 20px;
}

.p-table__wrap {
  margin: 20px 20px 40px 20px;
  padding: 16px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
}

p-table .p-datatable {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;

  .button {
    margin: 5px;
  }

  .p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $background;
  }

  .p-datatable-header {
    background-color: #fff;
    border: none;
  }

  .p-table__header-row {
    .p-sortable-column:focus {
      box-shadow: inset 0 0 0 0.15rem $background;
    }

    th.p-table__header-item {
      background-color: #fff;
      border: none;
      border-bottom: 1px solid $border-dark;
    }
  }

  .p-datatable-tbody .p-table__body-row {
    .table-item {
      border-top: 1px solid $border;
    }
    .table-item_first {
      @media screen and (max-width: 959px) {
        border-top: none;
      }
    }
  }

  .p-table__empty-row > td {
    border-bottom: none !important;
  }

  .p-paginator {
    .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-last:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
      color: $background-dark;
    }
    .p-paginator-pages .p-paginator-page.p-highlight {
      color: var(--color-white);
      background-color: $background;
      border-color: $background;
    }
    .p-paginator-element {
      color: $background;
    }
  }
}


// Modal filter styles block.
.p-column-filter-overlay.p-column-filter-overlay-menu {
  border-radius: 8px;
  font-size: 16px;

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: $foreground;
    background-color: $background;
  }

  .p-column-filter-add-button {
    color: $background;
  }

  .p-column-filter-add-button:hover {
    color: #244d84 !important;
  }

  .p-column-filter-constraints {
    .p-inputtext:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem $background;
      border-color: $accent-blue-title;
    }
  }

  .p-component.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $background;
    border-color: $accent-blue-title;
  }

  .p-column-filter-buttonbar {
    .p-button {
      width: 148px;
      height: 48px;
      background: $accent-blue-title;
      border-radius: 200px;
      border: none;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.75px;
      text-transform: uppercase;
      color: $background;

      &:hover {
        background-color: $background;
        border: none;
        color: var(--color-white);

        & .icon-btn_add {
          color: var(--color-white);
        }
      }

      &:active {
        border: none;
        background-color: #5d8bc6;
        color: $background-dark;

        & .icon-btn_add {
          color: $background-dark;
        }

        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $background;
      }

      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $background;
      }
    }

    .p-button.p-button-outlined {
      background: #fff;
      border: 1px solid $background;
      margin-right: 20px;
      color: $background;

      &:hover {
        background-color: $background;
        color: #fff;
        border: 1px solid $background;
      }

      &:active {
        outline: none;
        border: none;
        background-color: #5d8bc6;
        color: $background-dark;
        & .icon-btn_add {
          color: $background-dark;
        }
      }
    }
  }
}
// Modal filter styles block.


// Responsive styles block.
.table-item {
  word-wrap: break-word;
}


.p-table_responsive_true .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 1279px) {
  .p-datatable {

    &.p-table_responsive_md {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        padding: 10px;

        .p-column-title {
          padding: 5px;
          display: inline-block;
          width: 30%;
          font-weight: bold;
          @media screen and (min-width: 600px) and (max-width: 767px) {
            width: 40%;
          }
          @media screen and (max-width: 599px) {
            width: 45.5%;
          }
        }

        &:last-child {
          border-bottom: 2px solid $border-dark;
          border-radius: 0;
        }
      }

      .p-datatable-tbody > tr:last-child > td {
        &.table-item_last {
          border-bottom: none;
        }
      }
    }

  }
}

@media screen and (max-width: 959px) {
  .p-table__wrap {
    margin: 20px 0 40px 0;
  }

  .p-datatable {
    &.p-component {
      @media screen and (max-width: 599px) {
        font-size: 14px;
      }
    }

    &.p-table_responsive_true {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        padding: 10px;

        .p-column-title {
          padding: 5px;
          display: inline-block;
          width: 30%;
          font-weight: bold;
          @media screen and (min-width: 600px) and (max-width: 767px) {
            width: 40%;
          }
          @media screen and (max-width: 599px) {
            width: 45.5%;
          }
        }

        &:last-child {
          border-bottom: 2px solid $border-dark;
          border-radius: 0;
        }
      }

      .p-datatable-tbody > tr:last-child > td {
        &.table-item_last {
          border-bottom: none;
        }
      }
    }

  }
}
// Responsive styles block.
