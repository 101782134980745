.StripeElement {
  box-sizing: border-box;

  height: 40px;
  width: 100%;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.pay-card-element {
  height: 40px;
  width: 100%;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

form.ElementsApp {
  padding: 10px;
}

.pay-container {
  max-width: 470px;
  width: 100%;
  margin: 0 auto;
}

.pay-header {
  margin-top: 60px;
  font-size: 30px;
}

.pay-form-group {
  color: #383838;
  font-size: 22px;
  min-height: 90px;
}

.coupon {
  padding-top: 20px;
  min-height: inherit;
}

.payment-button {
  margin-top: 20px;
}

.payment-form {
  margin-top: 20px;
}

.stripe-badge {
  float: right;
}

.pay-old-price {
  text-align: center;
  color: #616161;
  text-decoration: line-through;
}

.input-has-discount {
  background: url('/assets/images/check-circle-regular--blue.svg') center right 7px no-repeat;
  background-size: 20px;
  padding-right: 34px;
}

.paymentCard {
  position: relative;
  width: 302px;
  min-height: 235px;
  margin: 0 auto;
  margin-top: 32px;
  padding-top: 30px;
  padding-bottom: 40px;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.12);
}

.paymentCard__priceOld {
  font-size: 16.5px;
  font-weight: 300;
  letter-spacing: 0.28px;
  text-align: center;
  color: #616161;
  text-decoration: line-through;
}

.paymentCard__pricePromo {
  font-size: 31.5px;
  font-weight: 400;
  letter-spacing: 0.52px;
  text-align: center;
  color: #e0060a;
}

.paymentCard__priceCommon {
  font-size: 31.5px;
  font-weight: 400;
  letter-spacing: 0.52px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.paymentCard__year {
  font-size: 18px;
}

.paymentCard__title {
  margin-top: 14px;
  font-size: 20px;
  letter-spacing: 0.35px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.paymentCard__details {
  margin-top: 14px;
  padding: 0 25px;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.67;
  letter-spacing: 0.31px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.paymentCard__features {
  text-align: left;
  font-size: 17px;
  padding-bottom: 20px;
}

.paymentCard__select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  width: 182px;
  height: 36px;
  border-radius: 36px;
  background-color: rgba(99, 15, 204, 0.6);
  color: white;
  font-size: 13.5px;
  font-weight: 500;
  line-height: 1.78;
  letter-spacing: 0.82px;
  text-align: center;
}

.paymentCard__offer {
  position: absolute;
  top: 26px;
  right: -37px;
  padding: 6px 28px;
  background-image: linear-gradient(to right, #00b3ba, #2eceab 92%, #2fcfab);
  transform: rotate(45deg);
  font-size: 16.5px;
  font-weight: 300;
  line-height: 1.27;
  letter-spacing: 0.75px;
  color: white;
}

.rt-stripe {
  .pay-card-element {
    padding: 20px 16px;
    height: 58px;
    background: #f8f8f8;
    border-radius: initial;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
  }

  .card-label {
    margin-bottom: 5px;
    padding-left: 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
  }

  .StripeElement--invalid {
    border-color: transparent;
    border-bottom: 1px solid #e53935;

    mat-error {
      margin-top: 5px;
      font-size: 15px;
      font-weight: 400;
    }
  }

  .pay-form-group {
    .mat-error {
      margin-top: 5px;
      font-size: 15px;
      font-weight: 400;
    }
  }

  .pay-form-group.coupon {
    input {
      padding: 20px 16px;
      height: 58px;
      background: #f8f8f8;
      border-radius: initial;
      border: none;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
      color: #000000;
    }

    input.input-has-discount {
      background: url('/assets/images/check-circle-regular--blue.svg') 7px no-repeat, #f8f8f8;
      background-size: 20px;
      background-position: center right 15px;
    }

    label {
      margin-bottom: 5px;
      padding-left: 16px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
      font-weight: 400;
    }
  }
}

