// Overwrite Material Design variables.
html {
  // Material menu item overrides.
  --mat-menu-item-label-text-font: Montserrat, sans-serif;
  --mat-menu-item-label-text-tracking: 0;
  --mat-form-field-container-text-line-height: 1;

  // Material form field overrides.
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-state-layer-color: inherit;
  --mdc-filled-text-field-container-color: inherit;
  --mat-form-field-container-text-font: Montserrat, sans-serif;
  --mat-form-field-filled-with-label-container-padding-top: 14px;
  --mat-form-field-filled-with-label-container-padding-bottom: 14px;
  --mat-form-field-container-height: 48px;
  --mdc-filled-text-field-input-text-color: var(--color-dark);
  --mdc-filled-text-field-input-text-placeholder-color: var(--color-gray3);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--color-gray3);
  --mat-form-field-disabled-input-text-placeholder-color: var(--color-gray3);

  // Material form trigger overrides.
  --mat-select-trigger-text-font: Montserrat, sans-serif;
  --mat-select-trigger-text-line-height: 20px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0;
  --mat-select-trigger-text-weight: 400;
  --mat-select-placeholder-text-color: var(--color-gray3);

  // Material form options overrides.
  --mat-option-selected-state-label-text-color: var(--color-blue);
  --mat-option-label-text-color: var(--color-dark);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);


  // Material form field checkbox overrides.
  .mat-accent, .mat-mdc-radio-button.mat-accent {
    --mdc-checkbox-selected-icon-color: var(--color-blue);
    --mdc-circular-progress-active-indicator-color: var(--color-blue);
    --mat-slider-ripple-color: var(--color-blue);
    --mat-slider-hover-state-layer-color: rgba(255, 64, 129, 0.05);
    --mat-slider-focus-state-layer-color: rgba(255, 64, 129, 0.2);
    --mdc-slider-handle-color: var(--color-blue);
    --mdc-slider-focus-handle-color: var(--color-blue);
    --mdc-slider-hover-handle-color: var(--color-blue);
    --mdc-slider-active-track-color: var(--color-blue);
    --mdc-slider-inactive-track-color: var(--color-blue);
    --mdc-slider-with-tick-marks-inactive-container-color: var(--color-blue);
    --mdc-checkbox-selected-hover-state-layer-color: var(--color-blue);
    --mdc-checkbox-selected-hover-icon-color: var(--color-blue);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--color-blue);
    --mdc-checkbox-selected-pressed-icon-color: var(--color-blue);
    --mat-full-pseudo-checkbox-selected-icon-color: var(--color-blue);
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--color-blue);
    --mat-option-selected-state-label-text-color: var(--color-blue);
    --mdc-checkbox-selected-focus-icon-color: var(--color-blue);
    --mdc-checkbox-selected-focus-state-layer-color: var(--color-blue);
    --mdc-checkbox-unselected-icon-color: var(--color-gray2);
    --mdc-checkbox-unselected-hover-icon-color: var(--color-gray2);
    --mdc-checkbox-unselected-focus-icon-color: var(--color-gray2);
    --mdc-checkbox-unselected-active-icon-color: var(--color-gray2);
    --mdc-checkbox-unselected-pressed-icon-color: var(--color-gray2);
    --mdc-checkbox-unselected-focus-state-layer-color: var(--color-gray2);
    --mdc-checkbox-unselected-hover-state-layer-color: var(--color-gray2);
    --mdc-checkbox-unselected-pressed-state-layer-color: var(--color-gray2);
    --mdc-checkbox-selected-focus-state-layer-opacity: 0.04;
    --mdc-checkbox-selected-hover-state-layer-opacity: 0.16;
    --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
    --mdc-checkbox-unselected-hover-state-layer-opacity: 0.16;
    --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;

    --mdc-radio-disabled-selected-icon-color: black;
    --mdc-radio-disabled-unselected-icon-color: black;
    --mdc-radio-unselected-hover-icon-color: #212121;
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: var(--color-blue);
    --mdc-radio-selected-hover-icon-color: var(--color-blue);
    --mdc-radio-selected-icon-color: var(--color-blue);
    --mdc-radio-selected-pressed-icon-color: var(--color-blue);
    --mat-radio-ripple-color: black;
    --mat-radio-checked-ripple-color: var(--color-blue);
    --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
    --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
  }

  // Material tooltip overrides.
  --mat-toolbar-title-text-font: Montserrat, sans-serif;
  --mat-toolbar-title-text-line-height: 17px;
  --mat-toolbar-title-text-size: 14px;
  --mat-toolbar-title-text-tracking: 0;
  --mat-toolbar-title-text-weight: 300;
  --mdc-plain-tooltip-supporting-text-font: Montserrat, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 14px;
  --mdc-plain-tooltip-supporting-text-weight: 300;
  --mdc-plain-tooltip-supporting-text-tracking: 0;


  // Material label overrides.
  --mat-checkbox-label-text-font: "Montserrat", sans-serif;
  --mat-checkbox-label-text-line-height: 1;
  --mat-checkbox-label-text-size: 16px;
  --mat-checkbox-label-text-weight: 400;
  --mat-checkbox-label-text-tracking: 0;
  --mat-checkbox-label-text-color: var(--color-dark);
}

// Hide the underline from Material Design form fields.
.mdc-line-ripple {
  display: none;
}

// Create rounded borders for Material Design form fields.
.mat-mdc-text-field-wrapper.mdc-text-field {
  border: 1px solid var(--color-gray3);
  border-radius: 4px;

  &.mdc-text-field--invalid {
    border-color: var(--color-light-coral);
  }
}

// Set gray background for readonly Material Design form fields.
.mat-mdc-form-field.readonly {
  .mdc-text-field {
    --mdc-filled-text-field-container-color: var(--color-gray1);
  }
}

// Set label to be present on top of Material Design form fields and errors to be displayed below.
.mat-mdc-form-field.mat-mdc-form-field-label-always-float {
  padding-top: 20px;

  label.mdc-floating-label.mat-mdc-floating-label {
    color: #747582;
    top: 0;
    left: -16px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field {
    overflow: visible;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .mat-mdc-form-field-infix {
    width: auto;
  }
}

// Set project-specific styles for Material Design checkbox errors.
.b-checkbox {
  .mat-mdc-form-field-error {
    display: block;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0;
    margin-left: -2px;
    margin-top: -2px;
    font-size: 14px;
    color: var(--color-coral);
  }
}

// Set project-specific styles for Material Design select options.
.simple-select {
  .mat-mdc-option {
    color: var(--color-dark);
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 400;
  }
}


// Set project-specific styles for Material Design radio buttons.
.mat-mdc-radio-button {
  --mat-checkbox-label-text-font: "Montserrat", sans-serif;
  --mat-checkbox-label-text-line-height: 1;
  --mat-checkbox-label-text-size: 16px;
  --mat-checkbox-label-text-weight: 400;
  --mat-checkbox-label-text-tracking: 0;
  --mat-checkbox-label-text-color: var(--color-dark);

  .mdc-label {
    margin-bottom: 0;
    padding-left: 0;
  }
}

// Increase the size of Material Design checkboxes.
.mdc-form-field {
  .mdc-checkbox {
    --mdc-checkbox-size: 28px;
    padding: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-size)) / 2);
    margin: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2);
    width: var(--mdc-checkbox-size);
    height: var(--mdc-checkbox-size);
    flex: 0 0 var(--mdc-checkbox-size);

    .mdc-checkbox__background {
      top: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-size)) / 2);
      left: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-size)) / 2);
      width: var(--mdc-checkbox-size);
      height: var(--mdc-checkbox-size);
      border-width: 1px;
    }

    .mdc-checkbox__checkmark {
      --mdc-checkbox-icon-size: 19px;
      width: var(--mdc-checkbox-icon-size);
      height: var(--mdc-checkbox-icon-size);
      margin-top: calc(calc((var(--mdc-checkbox-size) - var(--mdc-checkbox-icon-size)) / 2) - .5px);
      margin-left: calc(calc((var(--mdc-checkbox-size) - var(--mdc-checkbox-icon-size)) / 2) - .5px);
    }
  }

  .mdc-label {
    font-family: "Montserrat", sans-serif;
    line-height: 1;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    color: #747582;
    margin-bottom: 0;
  }
}

// Update Material Design tooltip styles.
.mat-mdc-tooltip .mdc-tooltip__surface {
  padding: 4px 12px 6px 10px;
}

// Set project-specific styles for Material Design labels.
mat-label .label {
  font-family: "Montserrat", sans-serif;
  line-height: 1;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  color: #747582;
  text-transform: uppercase;
  margin-left: 1px;
}

// Set project-specific styles for Material Design select components.
.mat-mdc-select-arrow-wrapper {
  height: auto;

  .mat-mdc-select-arrow {
    margin-bottom: -20px;
  }
}

// Material progress bar overrides.
.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--color-blue);
  --mdc-linear-progress-track-color: #2e4fd730;
}

// Tweak Material Design slider to always fill the parent container.
.mdc-slider.mat-mdc-slider {
  width: 100%;
  margin: 0;
}

.mat-datepicker-content {
  --mdc-text-button-label-text-font: "Montserrat", sans-serif;
  --mat-datepicker-calendar-text-font: "Montserrat", sans-serif;
  --mdc-text-button-label-text-tracking: 0;

  font-family: "Montserrat", sans-serif;
}

// Set project-specific styles for Material Design date picker errors.
.datapicker-error-container {
  margin-top: -20px !important;
}


// Enable the line break with '\n' for the tooltip.
.brainkey-tooltip {
  white-space: pre-line;
}

.rt-input_inline-outlined {
  --mat-form-field-container-vertical-padding: 10px;
  --mat-form-field-filled-with-label-container-padding-top: 2px;
  --mat-form-field-filled-with-label-container-padding-bottom: 2px;
  --mat-form-field-container-height: 24px;
}

// Remove unnecessary space added by the header of the info window in the Google Map.
.map-container {
  .gm-style-iw-d {
    margin-top: -35px;
  }
}

// Set the white background for inputs on the DocHealth check-in form.
.public-dochealth-check-in-form__form {
  --mdc-filled-text-field-container-color: #FFFFFF;
}
