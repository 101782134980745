// The file contains specific Material Design overwrites to be applied to only specific scope.

// Hide all labels from Material Design form fields.
.rt-material-utilities_hidden-labels {
  .mat-mdc-form-field {
    padding-top: 0;
  }
  --mat-form-field-filled-label-display: none;
}

// Hide all subscripts from Material Design form fields.
.rt-material-utilities_hidden-subscript {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.rt-material-utilities_inline-selectors {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    border: none;
    padding: 0;

    .mat-mdc-form-field-infix {
      padding: 0;
    }

    .mat-mdc-select-value {
      width: auto;
    }

    .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
      margin-bottom: 0;
      margin-left: 5px;
    }
  }

}
