.papaya-axial-slice-left-inscription,
.papaya-axial-slice-right-inscription,
.papaya-coronal-slice-left-inscription,
.papaya-coronal-slice-right-inscription {
  color: white;
  font-size: 14px !important;
  font-family: sans-serif !important;
  pointer-events: none;
}

.papaya-coronal-slice-name-inscription,
.papaya-axial-slice-name-inscription,
.papaya-sagittal-slice-name-inscription {
  color: lightgray;
  font-size: 11px !important;
  font-family: sans-serif !important;
  pointer-events: none;
}

// Do not remove. Hide the papaya toolbar added by the Papaya library.
.papaya-toolbar {
  display: none;
}
