@import 'vars';

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

*:-moz-placeholder {
  color: #666;
  opacity: 1;
}

*::-moz-placeholder {
  color: #666;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}

html {
  font-family: Montserrat, sans-serif;
}

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  color: #666;
}

body input:required:valid,
body textarea:required:valid {
  color: #666;
}

body {
  font-size: 18px;
  min-width: 320px;
  font-weight: 300;
  position: relative;
  line-height: 1.2;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow-x: hidden;
}

a {
  cursor: pointer;
}

.button, .button:hover {
  background-color: transparent;
  color: var(--color-white);
  font-size: 18px;
  font-weight: 500;
  padding: 9px 24px;
  min-width: 154px;
  border-radius: 70px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.73px;
  cursor: pointer;
}

@media (max-width: 599px) {
  .button, .button:hover {
    font-size: 14px;
  }
}

.button-primary, .button-primary:hover {
  color: var(--color-white);
  background-color: var(--color-blue);
  border: solid 3px var(--color-blue);
  text-decoration: none;
}

.button-primary:hover {
  box-shadow: 0 19px 22px 2px rgba(16, 15, 204, 0.23);
}

.button-outline, .button-outline:hover {
  color: var(--color-blue);
  background-color: var(--color-white);
  border: solid 3px var(--color-blue);
  text-decoration: none;
}

.button-outline:hover {
  box-shadow: 0 19px 22px 2px rgba(16, 15, 204, 0.23);
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1150px;
}

@media (max-width: 599px) {
  .container {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.menu-logo {
  margin-bottom: 10px;
  max-width: 204px;
  z-index: 1;

  img {
    width: 250px;
  }
}

.notifications-content__message a {
  color: var(--color-purple);
  font-weight: 600;
}

@media (max-width: 599px) {
  .menu-logo {
    text-align: center;
  }
}

.header {
  font-size: 40px;
  letter-spacing: 0.67px;
  color: rgba(0, 0, 0, 0.54);
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.public-text-page {
  margin-bottom: 40px;

  h1, h2, h3, h4, h5, th, header {
    font-weight: 500;
    font-family: 'Fira Sans', sans-serif;
    color: var(--color-gray4);
  }

  a {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 500;
  }

  header {
    font-size: 40px;
    line-height: 48px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 30px;
    line-height: 36px;
    margin-top: 40px;
  }

  h2 {
    font-size: 22px;
    line-height: 26px;
    margin-top: 20px;
  }

  th {
    font-size: 18px;
    line-height: 22px;
  }

  p, td, ul, li {
    font-size: 16px;
    line-height: 25px;
    font-weight: normal;
    color: var(--color-gray4);
    font-family: 'Montserrat', sans-serif;
  }
}

