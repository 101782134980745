// Styles for files uploading.
.drag-n-drop {
  margin: 0 auto;
  margin-top: 56px;
  position: relative;
  max-width: 605px;
  height: 400px;
  border-radius: 10px;
  border: 3px solid #bec0c1;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drag-n-drop__icon {
  width: 420px;

  @media (max-width: 599px) {
    width: 300px;
  }
}

.drag-n-drop__arrow {
  position: absolute;
  top: 40%;
}

.drag-n-drop__details {
  margin-top: 12px;
  font-size: 25px;
  letter-spacing: 0.47px;
  font-weight: 500;
  color: var(--color-blue);
}

.drag-n-drop__btnList {
  max-width: 605px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  display: flex;
  justify-content: center;

  @media (max-width: 599px) {
    flex-direction: column;
    align-items: center;
  }
}

.drag-n-drop__btn {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 178px;
  height: 48px;
  border-radius: 48px;
  border: solid 3px var(--color-blue);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.82px;
  text-transform: capitalize;
  color: var(--color-blue);
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 19px 22px 2px rgba(16, 15, 204, 0.23);
  }
}

@media (max-device-width: 767px) {
  .drag-n-drop {
    width: 100%;
    border: none;
    height: auto;
  }

  .drag-n-drop__details,
  .drag-n-drop__icon,
  .drag-n-drop__arrow {
    display: none;
  }
}

.upload-series__progress-bar {
  margin: 0 auto;
  max-width: 600px;
  height: 40px;
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
  background-color: #e1e3e7;
  overflow: hidden;
}

.upload-series__progress-fill {
  height: 100%;
  width: 0;
  background-color: var(--color-blue);
}

// Hide the related choose file inputs.
#file-upload, #folder-upload {
  display: none;
}
